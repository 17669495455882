import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"

import { useRichText, useContentfulComponent, richTextBody } from "utils"
import { FaxActivateForm } from "components/forms"

const FaxActivateTerms = ({ title, components }) => {
  const { fax_activate_terms_body, fax_activate_form_url } =
    useContentfulComponent(components)

  const termBody = useRichText({ text: fax_activate_terms_body.body })

  const renderStep = () => (
    <Step>
      <div className="body">{termBody}</div>
      <div className="formWrap">
        <FaxActivateForm {...fax_activate_form_url} />
      </div>
    </Step>
  )

  return (
    <StyledFaxActivateTerms>
      <VSection title={title} titleAs="h1" custom={renderStep} />
    </StyledFaxActivateTerms>
  )
}

const StyledFaxActivateTerms = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);

  &.showForm {
    --section-mobile-padding-left: 0;
    --section-mobile-padding-right: 0;
  }

  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
  }
`

const Step = styled.div`
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  .body {
    ${richTextBody};
    margin-top: var(--sp-64);
    p,
    li,
    ol,
    h5 {
      color: var(--card-shade_3);
    }
    a {
      color: var(--link_light);
    }

    h5 {
      margin-bottom: var(--sp-16);
    }
  }

  .formWrap {
    width: 100%;
    margin-top: var(--sp-64);
  }

  .agree_to_terms {
    margin-top: var(--sp-48);
    --button-background: var(--greenDark);
  }
`

export default FaxActivateTerms
