import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { TextGroup, BasicCard } from "components/common"
import { useLocation } from "@reach/router"

import { device } from "utils"

const ContactUsFormHero = ({ subtitle, url }) => {
  const { search } = useLocation()
  const [urlWithSearch, setUrl] = useState()

  useEffect(() => {
    setUrl(url + search)
  }, [])

  return (
    <StyledOperoForm>
      <BasicCard>
        <TextGroup subtitle={subtitle} />

        <div className="iframeContainer">
          {urlWithSearch && (
            <iframe
              id="opero form"
              title="opero form"
              src={urlWithSearch}
              frameBorder="0"
            />
          )}
        </div>

        <script src="https://ramsey.tfaforms.net/js/iframe_resize_helper.js"></script>
      </BasicCard>
    </StyledOperoForm>
  )
}

const StyledOperoForm = styled.div`
  max-width: 700px;
  margin: 0 auto;

  --card-padding: var(--sp-16);
  @media ${device.laptop} {
    --card-padding: var(--sp-96);
  }

  .tgWrap {
    margin-bottom: var(--sp-16);
  }

  .iframeContainer {
    height: 3020px;

    @media ${device.laptop} {
      height: 3020px;
    }

    iframe {
      height: 100%;
      width: 100%;
    }
  }
`

export default ContactUsFormHero
